<template>
  <div class="auth-bg min-h-screen h-max lg:flex">
    <auth-layout/>
    <div class="w-full h-screen pb-10 lg:mr-1/2 xxl:ml-auto px-4 sm:px-6 lg:px-12 xl:pl-24 xl:pr-20 lg:mt-16 lg:max-w-xxl">
      <a href="https://loanspot.africa" class="block mt-8 cursor-pointer">
        <img class="hidden lg:inline" src="/assets/images/LoanSpot-logo.svg" />
        <img class="lg:hidden block w-max mx-auto" src="/assets/images/LoanSpot-logo_white.svg" />
      </a>
      <div class="mt-10 bg-white px-5 sm:px-6 lg:px-0 rounded-xl lg:rounded-none">
        <h3 class="big-title text-5 xl:text-8 text-title pt-8 lg:pt-0">
          Hey, Welcome Back!
        </h3>
        <p class="text-sm text-sec-text mt-1 xl:mt-2 xxs:w-72 xl:w-full">
          Incredible loan deals have been awaiting you.
        </p>
        <form @submit.prevent="login" class="mt-10">
          <div class="flex flex-col mt-8">
            <label class="text-2 text-xs inline-block text-title title">Email Address
            </label>
            <div class="flex items-center w-full rounded-lg mt-1">
              <svg class="absolute ml-4" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.666667 0H12.6667C12.8435 0 13.013 0.0702379 13.1381 0.195262C13.2631 0.320286 13.3333 0.489856 13.3333 0.666667V11.3333C13.3333 11.5101 13.2631 11.6797 13.1381 11.8047C13.013 11.9298 12.8435 12 12.6667 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V0.666667C0 0.489856 0.0702379 0.320286 0.195262 0.195262C0.320286 0.0702379 0.489856 0 0.666667 0ZM12 2.82533L6.71467 7.55867L1.33333 2.81067V10.6667H12V2.82533ZM1.674 1.33333L6.70733 5.77467L11.668 1.33333H1.674Z" fill="#6D7D93"/>
              </svg>
              <input
                class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                type="email"
                name="Email"
                autocomplete="off"
                required
                v-model="formBody.email"
                placeholder="adeniji-fashola@gmail.com"
              />
            </div>
          </div>

          <div class="flex flex-col input-wrap mt-8">
            <label class="text-xs inline-block text-title title">Password</label>
            <div class="flex items-center w-full rounded-lg mt-1">
              <svg class="absolute ml-4" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6667 5.33333H11.3333C11.5101 5.33333 11.6797 5.40357 11.8047 5.5286C11.9298 5.65362 12 5.82319 12 6V12.6667C12 12.8435 11.9298 13.013 11.8047 13.1381C11.6797 13.2631 11.5101 13.3333 11.3333 13.3333H0.666667C0.489856 13.3333 0.320286 13.2631 0.195262 13.1381C0.0702379 13.013 0 12.8435 0 12.6667V6C0 5.82319 0.0702379 5.65362 0.195262 5.5286C0.320286 5.40357 0.489856 5.33333 0.666667 5.33333H1.33333V4.66667C1.33333 4.05383 1.45404 3.447 1.68856 2.88081C1.92308 2.31462 2.26683 1.80018 2.70017 1.36683C3.13351 0.933495 3.64796 0.589751 4.21414 0.355229C4.78033 0.120707 5.38716 0 6 0C6.61284 0 7.21967 0.120707 7.78586 0.355229C8.35204 0.589751 8.86649 0.933495 9.29983 1.36683C9.73317 1.80018 10.0769 2.31462 10.3114 2.88081C10.546 3.447 10.6667 4.05383 10.6667 4.66667V5.33333ZM1.33333 6.66667V12H10.6667V6.66667H1.33333ZM5.33333 8H6.66667V10.6667H5.33333V8ZM9.33333 5.33333V4.66667C9.33333 3.78261 8.98214 2.93477 8.35702 2.30964C7.7319 1.68452 6.88406 1.33333 6 1.33333C5.11595 1.33333 4.2681 1.68452 3.64298 2.30964C3.01786 2.93477 2.66667 3.78261 2.66667 4.66667V5.33333H9.33333Z" fill="#6D7D93"/>
              </svg>
              <input
                class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-10 pl-10"
                :type="showPassword ? 'password' : 'text'"
                required
                autocomplete="off"
                v-model="formBody.password"
                placeholder="••••••••"
              />
              <span
                ref="passwordShow"
                @click.prevent="showPassword = !showPassword"
                class="cursor-pointer text-xs text-sec-text -ml-12 z-50 input-icon"
              >
                {{ showPassword ? "Show" : "Hide" }}
              </span>
            </div>
          </div>

          <a href="/password/forgot" class="w-max text-primary text-sm block mt-3 ml-auto">Forgot password?</a>

          <Button
            id="login-btn"
            customClass="focus:outline-none mt-10 flex items-center justify-center bg-primary w-full rounded-lg h-14 hover:bg-bg-hover text-white font-medium text-sm tracking-wider"
            :isDisabled="isRequest === true"
          >
            <span
              slot="spin"
              class="animate-spin"
              :class="spinner ? '' : 'hidden'"
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M13.75 7C13.75 10.7279 10.7279 13.75 7 13.75C3.27208 13.75 0.25 10.7279 0.25 7C0.25 3.27208 3.27208 0.25 7 0.25C10.7279 0.25 13.75 3.27208 13.75 7ZM1.6 7C1.6 9.98234 4.01766 12.4 7 12.4C9.98234 12.4 12.4 9.98234 12.4 7C12.4 4.01766 9.98234 1.6 7 1.6C4.01766 1.6 1.6 4.01766 1.6 7Z" fill="#EFEFEF"/>
                <path d="M7 13.075C7 13.4478 6.69704 13.7535 6.32611 13.7163C5.37081 13.6204 4.44421 13.3216 3.60954 12.8367C2.57975 12.2385 1.72655 11.3785 1.13655 10.344C0.546549 9.3095 0.240758 8.1373 0.250213 6.94641C0.259668 5.75552 0.584032 4.58833 1.19038 3.56331C1.79673 2.5383 2.66348 1.69195 3.70264 1.11018C4.74181 0.528418 5.91639 0.231942 7.10717 0.250851C8.29795 0.26976 9.46253 0.60338 10.4827 1.21785C11.3096 1.71589 12.0177 2.38405 12.5617 3.17514C12.773 3.48231 12.6509 3.89503 12.3242 4.07455C11.9975 4.25407 11.5904 4.13163 11.3715 3.82984C10.9474 3.24504 10.4089 2.74935 9.78616 2.37428C8.97003 1.8827 8.03836 1.61581 7.08574 1.60068C6.13311 1.58555 5.19345 1.82273 4.36211 2.28815C3.53078 2.75356 2.83738 3.43064 2.3523 4.25065C1.86723 5.07066 1.60773 6.00442 1.60017 6.95713C1.59261 7.90984 1.83724 8.8476 2.30924 9.67521C2.78124 10.5028 3.4638 11.1908 4.28763 11.6694C4.91623 12.0345 5.61 12.2678 6.32674 12.3579C6.69663 12.4043 7 12.7022 7 13.075Z" fill="white"/>
              </svg>
            </span>
            <span slot="value" :class="spinner ? 'hidden' : 'title'">
              {{ loginText }}
            </span>
          </Button>
        </form>
        <p class="text-accent-3 text-sm pt-6 pb-10 text-center">Not registered yet? <a href="/signup" class="text-primary title">Create an account</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/shared/button";
import { AUTH_ROUTES } from "../../constants";
import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie';
import AuthLayout from '../../components/auth/auth-layout.vue';

export default {
  name: "Login",
  components: {
    AuthLayout,
    Button,
  },
  mounted() {
    mixpanel.track('Visited Login', {
      'distinct_id': Cookies.get('mix_id')
    });
  },
  data() {
    return {
      showPassword: true,
      isRequest: false,
      spinner: "",
      loginText: "Login",
      errors: {},
      routes: {
        register: AUTH_ROUTES.SIGNUP,
        forgotPassword: AUTH_ROUTES.FORGOT_PASSWORD
      },
      formBody: {
        email: "",
        password: "",
      },
      hasError: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Loanspot",
        complement: "Log in to your account",
      };
    },
    meta: [
      { name: "description", content: "Loanspot login page" },
      { name: "application-name", content: "Loanspot" },
    ],
  },
  methods: {
    borderFill(e) {
      if (e.target.value === "") {
        e.target.style.border = "1px solid rgba(229, 233, 242, 1)";
      } else {
        e.target.style.border = "1px solid rgba(22, 86, 193, 0.5)";
      }
    },
    async login() {
      this.spinner = true;
      try {
        const response = await this.$store.dispatch("loginUser", this.formBody);
        this.spinner = false;
        document.getElementById("login-btn").style.backgroundColor = "#27BD8B";
        this.loginText = "Login successful";
        if (response.status) {
          if (response.pending)
            return (window.location.href = response.pending);
          else
            window.location.href = this.$route.query.redirect
              ? this.$route.query.redirect
              : "/";
        }
      } catch (error) {
        document.getElementById("login-btn").style.backgroundColor = "#F23204";
        this.spinner = "";
        document.getElementById("login-btn").style.fontSize = "12px";
        this.loginText = error?.message || "Login failed";

        setTimeout(() => {
          document.getElementById("login-btn").style.fontSize = "14px";
          document.getElementById("login-btn").style.backgroundColor =
            "#1656C1";
          this.loginText = "Login";
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .auth-bg {
    background: linear-gradient(154.89deg, #5B7BFD -0.2%, #3752C1 82.72%);
    @media (min-width: 1024px) {
      background: #fff;
    }
  }
  .big-title {
    font-family: SohneHalbfett, sans-serif;
  }
  .title {
    font-family: SohneKraftig, sans-serif;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    font-family: SohneLeicht;
    font-size: 0.875rem;
  }
</style>